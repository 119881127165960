import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = ({ homeLoading }) => {
  if (!homeLoading)
    return (
      <div className="fixed bottom-0 h-32 md:h-56 left-2 md:left-6">
        <div className="flex flex-col space-y-3 cursor-pointer">
          <FontAwesomeIcon
            icon={['fab', 'linkedin']}
            title="https://www.linkedin.com/in/david-roqueni/"
            className=" hover:text-yellow-400 transform md:hover:scale-105 text-2xl md:text-4xl"
            size="2x"
            color="white"
            onClick={() =>
              window.open('https://www.linkedin.com/in/david-roqueni/')
            }
          />
          
          <FontAwesomeIcon
            icon={['fab', 'github']}
            size="2x"
            color="white"
            className=" hover:text-yellow-400 transform md:hover:scale-105  text-2xl md:text-4xl"
            title="https://github.com/DavidRoqueni"
            onClick={() => {
              window.open('https://github.com/DavidRoqueni')
            }}
          />
        </div>
      </div>
    )
  else return <div> </div>
}

export default Footer
