import React from 'react'
import { Link } from 'react-router-dom'

const NavBar = ({ locations }) => {
  return (
    <div className="container">
      <div className={'flex relative h-14 w-screen'}>
        <div className={'absolute bottom-1.5 right-5 space-x-5'}>
          <Link className={'navLink'} to="/home">
            Home
          </Link>
          <Link className={'navLink'} to="/about">
            About Me
          </Link>
          {/* <Link className={'navLink'} to='/blog'>
            Blog
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default NavBar
