import React from 'react'
import SlideShow from '../Components/SlideShow'

const AboutMePage = ({ introShowed }) => {
  introShowed(false)
  return (
    <div className="flex flex-col mr-12 ml-12 md:mr-auto md:ml-auto items-center">
      <SlideShow></SlideShow>
      <section id="who-i-am">
        <p className="text-xl max-w-3xl text-center text-gray-400">
          Hi, I am David Roqueni a Software Engineer out of Phoenix,
          Arizona. Growing up I was always interested in solving problems, and
          in school my favorite subject was math. When it came time for college
          something just drew my to computer science. Maybe it was the years of
          playing around on computers, or that both my parents worked in the tech
          sector. I can't tell you the number of times I asked my father to
          explain OOP to me as a kid.
        </p>
      </section>
      <br />
      <section id="who-i-am">
        <p className="text-xl max-w-3xl text-center text-gray-400 mb-12">
          While in school at Arizona State I was able to get an internship with
          a local company where I worked on a Xamarin.Forms application. I
          worked on getting to learn the ins and outs of how Xamarin worked and
          started to truly enjoy the feeling of being able to say 'I made that'.
          I began to learn more front end frameworks including Angular and
          ReactJS. After graduating from Arizona State I went to work at Valore
          Partners was a Associate Consultant, with a love for frontend and
          automation. Whether it is Xamarin.Forms or React, Bash or Powershell,
          I am constantly tinkering and learning. Recently I also started to practice and take part in competitive 
          programming events and have been working on honing my skills in this area.
          When I am not on the computer working with code, I can often be found out in nature fishing or
          riding bikes.
        </p>
      </section>
    </div>
  )
}

export default AboutMePage
