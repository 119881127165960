import { React } from 'react'

const SlideShow = () => {
  return (
    <div>
      <img
        className=" h-40 w-40 mt-5 mb-5 md:h-60 md:w-60 mr-auto ml-auto rounded-full"
        src={'./images/fishing.jpg'}
        alt="slide show"
      />
    </div>
  )
}

export default SlideShow
