import { React, useState } from 'react'

const HomePage = ({ parentCallback, showIntro }) => {
  const [loaded, setImageLoaded] = useState(false)
  const [showIntroHere, setshowIntroHere] = useState(showIntro)
  const handleImage = (value) => {
    setTimeout(() => {
      if (loaded !== value) setImageLoaded(value)
    }, 500)
  }
  if (showIntroHere)
    return (
      <div style={{ maxHeight: '60vh' }}>
        <div
          className="flex flex-col text-center items-center justify-center translate-x-3/4"
          style={{
            height: showIntroHere ? '90vh' : '0vh',
          }}
        >
          <div className="flex flex-row overflow-hidden">
            <div
              className="text-7xl text-yellow-300 font-semibold transform slide-right"
              onAnimationEnd={() => {
                parentCallback(false)
                setshowIntroHere(false)
              }}
            >
              Welcome
            </div>
          </div>
        </div>
      </div>
    )
  else
    return (
      <div style={{ maxHeight: '60vh' }}>
        <div
          className="flex flex-col h-full container m-auto"
          style={{ height: showIntroHere ? '' : '0vh !important' }}
        >
          <div className="flex-col px-12 md:px-60  mt-10 space-y-4">
            <img
              className={
                !loaded
                  ? 'h-60 w-60 mr-auto ml-auto opacity-0'
                  : 'h-60 w-60 mr-auto ml-auto rounded-full ease-in-out duration-2000'
              }
              src={'/images/hiking.jpg'}
              alt="me"
              onLoad={handleImage(true)}
            ></img>
            <div className={'flex flex-col'}>
              <div
                className={
                  !loaded
                    ? 'text-2xl text-yellow-200 opacity-0'
                    : 'text-2xl text-yellow-200 ease-in-out duration-2000'
                }
              >
                Hello, I am
              </div>
              <div
                className={
                  !loaded
                    ? 'text-6xl -ml-0.5 -mt-2 font-semibold text-gray-200  opacity-0'
                    : 'text-6xl -ml-0.5 -mt-2 font-semibold text-gray-200 ease-in-out duration-2000'
                }
              >
                David Roqueni
              </div>
            </div>
            <div
              className={
                !loaded
                  ? 'text-4xl text-gray-500 opacity-0'
                  : 'text-4xl text-gray-500 ease-in-out duration-2000'
              }
            >
              A Mobile and Web Developer
            </div>
            <p
              className={
                !loaded
                  ? 'text-xl max-w-4xl text-gray-400 opacity-0'
                  : 'text-xl max-w-4xl text-gray-400 ease-in-out duration-2000 delay-1000'
              }
            >
              Software engineer based out of Phoenix, Arizona working for Valore
              Partners building clients modern, elegant solutions for mobile and
              web platforms.
            </p>
            <button
              className={
                !loaded
                  ? 'bg-transparent hover:bg-yellow-200 hover:bg-opacity-90 text-yellow-200 font-semibold hover:text-gray-800 py-2 px-4 border border-yellow-200 hover:border-transparent rounded opacity-0'
                  : 'bg-transparent hover:bg-yellow-200 hover:bg-opacity-90 text-yellow-200 font-semibold hover:text-gray-800 py-2 px-4 border border-yellow-200 hover:border-transparent rounded duration-1000 delay-2000 ease-linear'
              }
              onClick={() =>
                (window.location = 'mailto:davidtroqueni@gmail.com')
              }
            >
              {!loaded ? '' : 'Contact Me'}
            </button>
          </div>
        </div>
      </div>
    )
}

export default HomePage
