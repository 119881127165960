import './App.css'
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import HomePage from './Pages/HomePage'
import NavBar from './Components/NavBar'
import { Fragment, useState } from 'react'
import AboutMePage from './Pages/AboutMePage'
import Footer from './Components/Footer'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)
const App = () => {
  const [isLoading, setisLoading] = useState(true)
  const [showIntro, setShowIntro] = useState(true)
  const parent = (loading) => {
    setisLoading(loading)
    setShowIntro(loading)
  }
  return (
    <Router>
      <Route
        render={() => (
          <Fragment>
            <NavBar />
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route
              exact
              path="/home"
              render={() => (
                <HomePage parentCallback={parent} showIntro={showIntro} />
              )}
            />
            <Route
              exact
              path="/about"
              render={() => <AboutMePage introShowed={parent} />}
            />
            <Footer homeLoading={isLoading} />
          </Fragment>
        )}
      />
    </Router>
  )
}
export default App
